
import React, { useState, useCallback } from 'react';

import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { useStyles } from "../../formStyles";

export const DispatchFields = ({ formData, ...rest }) => {
    const form = useForm();
    const classes = useStyles({});
    return (
        <>
        {rest.hasOtherDocEdit &&
        <div style={ { width: "100%" } }>

        <DateInput variant="outlined" label="Dispatched/Delivery date" source="deliveryDate" formClassName={ classes.one_4_input } initialValue="" />
        { "   " }
        <SelectInput variant="outlined" source="deliveryType" label="Courier/Hand delivery" choices={ [{ id: 'NA', name: 'NA' }, { id: 'Courier', name: 'Courier' }, { id: 'Handdelivery', name: 'Hand delivery' },] } formClassName={ classes.two_4_input } initialValue="NA" />
        { "   " }
        {
            formData.deliveryType === "Courier" || formData.deliveryType === "Handdelivery" ?
                <TextInput variant="outlined" validate={ [required()] } source="courierName" label=" Courier name" formClassName={ classes.three_4_input } initialValue="" />
                : null
        }

        { "   " }
        { formData.deliveryType === "Courier"  || formData.deliveryType === "Handdelivery" ?
            <TextInput variant="outlined" validate={ [required()] } source="podNo" label=" Pod No" formClassName={ classes.last_4_input } initialValue="" />
            : null
        }
        { "   " }
        {
            formData.deliveryType === "Courier"  || formData.deliveryType === "Handdelivery" ?
                <NumberInput variant="outlined" validate={ [required()] } source="deliveryamt" label=" Delivery amt" formClassName={ classes.one_4_input } initialValue="0" />
                : null
        }
        { "   " }
        { formData.deliveryType === "Courier"  || formData.deliveryType === "Handdelivery" ?
            <TextInput variant="outlined" validate={ [required()] } source="toLocation" label=" To Location" formClassName={ classes.last_4_input } initialValue="" />
            : null
        }
        { "   " }
        { formData.deliveryType === "Courier"  || formData.deliveryType === "Handdelivery" ?
            <NumberInput variant="outlined" validate={ [required()] } source="weightInKg" label=" Weight In Kg" formClassName={ classes.last_4_input } initialValue="" />
            : null
        }

    </div>}
        </>
        
    )
}
export default DispatchFields;